import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  data: function () {
    return {
      settings: {
        "arrows": true,
        "infinite": true,
        "initialSlide": 0,
        "speed": 500,
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "dots": false,
        "swipeToSlide" : true,
      },
      tipSettings: {
        "arrows": true,
        "infinite": true,
        "initialSlide": 0,
        "speed": 500,
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "dots": false,
        "swipeToSlide" : true,
      },
      labSettings: {
        "arrows": true,
        "infinite": true,
        "initialSlide": 0,
        "speed": 500,
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "dots": false,
        "swipeToSlide" : true,
      },
      mdSettings: {
        "arrows": true,
        "infinite": true,
        "initialSlide": 0,
        "speed": 500,
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "dots": false,
        "swipeToSlide" : true,
      },
      topBannerSettings: {
        "arrows": false,
        "infinite": true,
        "initialSlide": 0,
        "speed": 500,
        "autoplay": true,
        "autoplaySpeed": 5000,
        "slidesToScroll": 1,
        "dots": false,
        "swipeToSlide" : true,
        "vertical": true,
        // "fade":true,
      },
      bannerSettings: {
        "arrows": true,
        "infinite": true,
        "initialSlide": 0,
        "speed": 500,
        "autoplay": true,
        "autoplaySpeed": 5000,
        "slidesToScroll": 1,
        "dots": true,
        "swipeToSlide" : true,
      },
      tipName: null,
      tipList: null,
      tipSubName: null,
      labName: null,
      labList: null,
      labSubName: null,
      mdName: null,
      mdList: null,
      mdSubName: null,
    }
  },
  components: { VueSlickCarousel },
  methods:{
    updateMousePosition(event, type, target, id, index) {
      // 익스플로러의 버전 체크하기
      let version_IE = "0";
      let ieLower = navigator.userAgent.match( /MSIE [0-9]{1,}/ );
      if ( ieLower != null ){  version_IE = ieLower.toString().replace( "MSIE " , "" );  }

      let x = 0; // 마우스 포인터의 좌측 위치
      let y = 0; // 마우스 포인터의 위쪽 위치

      if ( 0 < version_IE && version_IE < 7 ) { // 인터넷 익스플로러 (ie) 6 이하 버전일 경우 적용될 내용
        x = event.offsetX;
        y = event.offsetY;
      }
      else if ( event.pageX ) { // pageX & pageY를 사용할 수 있는 브라우저일 경우
        x = event.pageX;
        y = event.pageY;
      }
      else { // 그외 브라우저용
        x = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        y = event.clientY + document.body.scrollTop + document.documentElement.scrollTop;
      }

      if (type == 'down') {
        this.mousedownPositionX = x;
        this.mousedownPositionY = y;
      }
      else {
        this.mouseupPositionX = x;
        this.mouseupPositionY = y;

        this.goDetail(target, id, index);
      }
    },
    goDetail(target, id, index) {
      if (this.mousedownPositionX == this.mouseupPositionX && this.mousedownPositionY == this.mouseupPositionY) {
        // 메인페이지에서 TIP, 연구소 상세 클릭 후 history back했을 때 클릭한 게시물이 제일 앞에 나타나도록 적용.
        localStorage.setItem("mainHistory", `${target},${index}`)
        this.$router.push(`/${target}/${id}`)
      }
    },
    setHeightForVerticalSlide() {
      if (document.getElementsByClassName("vertical").length === 0) return false
      const verticalSlide = document.getElementsByClassName("vertical")[0].getElementsByClassName("slick-slide")
      if (verticalSlide.length > 1) {
        let maxHeight = -1;
        verticalSlide.forEach((slide) => {
          const slideHeight = slide.clientHeight
          if (slideHeight > maxHeight) {
            maxHeight = slideHeight;
          }
        });
        verticalSlide.forEach((slide) => {
          const slideHeight = slide.clientHeight
          if (slideHeight < maxHeight) {
            slide.style.margin = Math.ceil((maxHeight-slideHeight)/2) + 'px 0';
          }
        });
      }
    }
  }
}
